import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { Container, Modal } from "reactstrap";
import { isEmpty, isEqual } from "lodash";
import FilterSearch from "src/components/Common/FilterSearch";
import toastr from "src/components/Common/toastr";
import LoadingSpinner from "src/components/LoadingSpinner";
import { DownloadIcon } from "@100mslive/react-icons";
import { Button, Flex, Text, Tooltip } from "@100mslive/roomkit-react";
import TableContainer from "./TableContainer";
import { fetchTemplatesData } from "../../actions/RolesActions";
import api from "../../api";
import TabGroup from "../../components/Common/TabGroup";
import { API_CALL_STATE } from "../../constants";
import { AppAnalytics } from "../../helpers/analytics_helper";
import jwt_verify from "../../helpers/token_expiry";
import {
  currentUser,
  getAPIURL,
  isValidObjectID,
  uniqueObjects,
} from "../../utils";
import "toastr/build/toastr.min.css";

class Sessions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      last: null,
      activeTab: "past",
      loading: true,
      hasSessions: false,
      templateLoading: true,
      liveSessions: [],
      pastSessions: [],
      email_sent: false,
      modal_small: false,
      resp_loading: false,
      email: null,
      filter: {
        room_id: "",
        session_id: "",
      },
    };
    this.tog_small = this.tog_small.bind(this);
    this.tableContainerRef = React.createRef();
    this.loadingRef = React.createRef();
  }

  tog_small() {
    this.setState(prevState => ({
      modal_small: !prevState.modal_small,
    }));
    if (this.state.modal_small === false) {
      this.setState({ email_sent: false, resp_loading: false });
    }
    this.removeBodyCss();
  }

  removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  handleSearch = val => {
    this.setState({ liveSessions: [], pastSessions: [], last: "" }, () => {
      if (isValidObjectID(val)) {
        this.globalSearch();
      }
    });
  };

  globalSearch = () => {
    let { filter } = this.state;
    this.getData("", filter);
  };

  getData = (last, filter) => {
    this.setState(state => ({ ...state, loading: true }));
    const user_obj = currentUser();
    if (!user_obj) {
      this.props.history.push("/login");
    }
    const status = this.state.activeTab;
    let params = { limit: 20, status, peercount_only: "true" };
    if (last) {
      params["start"] = last;
    }
    if (!isEmpty(filter)) {
      for (const [key, value] of Object.entries(filter)) {
        if (value) {
          params = { ...params, [key]: value };
        }
      }
    }
    api
      .service("dashboard")
      .get(getAPIURL("sessions", params))
      .then(response => {
        const res = response.data;
        if (!res.data) {
          this.setState(state => ({
            ...state,
            loading: false,
            last: "",
            liveSessions: state?.liveSessions || [],
          }));
        } else {
          const sessions = res.data;
          let updateData;
          if (status === "live") {
            let liveSessions = sessions;
            if (last) {
              liveSessions = uniqueObjects(
                [...this.state.liveSessions, ...sessions],
                "_id"
              );
            }
            updateData = {
              liveSessions,
              hasSessions: liveSessions.length > 0,
            };
          } else {
            const pastSessions = uniqueObjects(
              [...this.state.pastSessions, ...sessions],
              "_id"
            );
            updateData = {
              pastSessions,
              hasSessions: pastSessions.length > 0,
            };
          }
          this.setState(state => ({
            ...state,
            ...updateData,
            last: res.last,
            loading: false,
          }));
        }
      })
      .catch(err => {
        this.setState(state => ({
          ...state,
          liveSessions: [],
          pastSessions: [],
          loading: false,
          last: "",
        }));
        console.error(err);
      });
  };

  componentDidMount() {
    const verified = jwt_verify();
    if (verified) {
      toastr.options.closeButton = true;
      if (isEmpty(this.props.all_templates)) {
        this.props.fetchTemplatesData();
      }

      this.getData("", {});
    }
    this.observer = new IntersectionObserver(
      entities => {
        if (entities[0].isIntersecting) {
          if (
            this.state.filter.session_id &&
            isValidObjectID(this.state.filter.session_id)
          ) {
            this.getData("", this.state.filter);
          }
          if (!this.state.filter.session_id) {
            this.getData(this.state.last, this.state.filter);
          }
        }
      },
      {
        root: this.tableContainerRef.current,
        threshold: [0.5, 0.75, 1], // CHECK - which values to use since in TableContainer it had [0.5, 1]
      }
    );
    if (this.loadingRef.current) {
      this.observer.observe(this.loadingRef.current);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.activeTab !== this.state.activeTab) {
      this.setState(
        {
          filter: {
            session_id: "",
            room_id: "",
          },
          hasSessions: false,
          loading: false,
          liveSessions: null,
        },
        () => {
          this.getData("", this.state.filter);
        }
      );
    }

    if (
      !isEqual(prevState.filter, this.state.filter) &&
      !Object.values(this.state.filter).join("")
    ) {
      this.getData("", {});
    }
  }
  //eslint-disable-next-line complexity
  render() {
    const { liveSessions, pastSessions, modal_small, activeTab } = this.state;
    const tabs = [
      { name: "Past Sessions", id: 1, icon: <></>, title: "past" },
      {
        name: "Live Sessions",
        id: 0,
        icon: <></>,
        title: "live",
      },
    ];
    const sessions = activeTab === "live" ? liveSessions : pastSessions;

    const sessionDetailsFilters = [
      {
        label: "Room ID",
        placeholder: "Optional",
        _key: "room_id",
      },
    ];

    const validRoomId = this?.state?.filter?.room_id
      ? isValidObjectID(this?.state?.filter?.room_id)
      : true;
    const validSessionId = this?.state?.filter?.session_id
      ? isValidObjectID(this?.state?.filter?.session_id)
      : true;
    const validFilter = validRoomId && validSessionId;
    jwt_verify(this.props.history);

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex justify-content-space-between align-items-center">
                <Text
                  variant="h5"
                  css={{ color: "$on_surface_high", mr: "$4" }}
                >
                  Your Sessions
                </Text>
                <Tooltip
                  title={
                    this.state.hasSessions
                      ? "Download sessions data"
                      : "No sessions data to download"
                  }
                  side="right"
                >
                  <Button
                    outlined
                    variant="standard"
                    css={{
                      color: "$on_surface_high",
                      borderRadius: "$0",
                      padding: "$3 $4",
                    }}
                    disabled={!this.state.hasSessions}
                    onClick={() => {
                      try {
                        AppAnalytics.track("download.session_data.clicked", {
                          page: "/session",
                        });
                      } catch (e) {
                        console.error(e);
                      }
                      this.setState({ modal_small: true, resp_loading: true });
                      const user_obj = currentUser();
                      const email = user_obj.email;
                      this.setState({ email: email });
                      api
                        .service("dashboard")
                        .get("download-sessions")
                        .then(() => {
                          this.setState({
                            ...this.state,
                            email_sent: true,
                            resp_loading: false,
                          });
                        })
                        .catch(e => {
                          console.error(e);
                        });
                    }}
                  >
                    <DownloadIcon />
                    <Text
                      css={{
                        padding: "$0 $4",
                        fontSize: "$sm",
                        fontWeight: "$medium",
                        letterSpacing: "0.4px",
                      }}
                    >
                      Download
                    </Text>
                  </Button>
                </Tooltip>
              </div>
            </div>
            <Flex
              align="center"
              css={{
                color: "$primary_bright",
                mt: "$4",
                fontSize: "$xs",
              }}
            >
              <Text variant="caption" css={{ color: "$on_surface_medium" }}>
                Session displays peer communication in a room. A room can have
                multiple sessions.
              </Text>
              <a
                target="_blank"
                rel="noreferrer"
                style={{
                  color: "inherit",
                  lineHeight: "1rem",
                  fontSize: "inherit",
                }}
                href={`${process.env.REACT_APP_WEBSITE_URL}docs/javascript/v2/foundation/basics#basic-concepts`}
              >
                &nbsp;Learn more.
              </a>
            </Flex>
            <div className="flex-row flex justify-between mt-8 mb-8">
              <div className=" relative" style={{ width: "min(600px,50%)" }}>
                {!this.state.loading &&
                !(
                  (this.state.activeTab === "past" &&
                    this.state.pastSessions?.length) ||
                  (this.state.activeTab === "live" &&
                    this.state.liveSessions?.length)
                ) &&
                Object.values(this.state.filter).join("") === "" ? null : (
                  <>
                    <FilterSearch
                      placeholder="Search with Session ID"
                      componentId={`sessions.overview.page.${activeTab}`}
                      filters={sessionDetailsFilters}
                      invalidInputErrorString="Please check the session ID entered"
                      resetDisabled={!validFilter}
                      error={
                        sessions &&
                        !this.state.loading &&
                        sessions.length === 0 &&
                        Object.values(this.state.filter).join("") !== ""
                      }
                      applyDisabled={!validFilter}
                      inputValue={this.state.filter.session_id}
                      filterValues={this.state.filter}
                      onFilterChange={({ key, value }) =>
                        this.setState({
                          filter: { ...this.state.filter, [key]: value },
                        })
                      }
                      onFilterRemove={key => {
                        this.setState({
                          filter: { ...this.state.filter, [key]: "" },
                        });
                      }}
                      onInputChange={val =>
                        this.setState(
                          {
                            pastSessions: [],
                            liveSessions: [],
                            loading: false,
                            last: "",
                            filter: {
                              ...this.state.filter,
                              session_id: val,
                            },
                          },
                          () => {
                            if (val && isValidObjectID(val)) {
                              this.getData("", this.state.filter);
                            }
                            if (!val) {
                              this.getData("", this.state.filter);
                            }
                          }
                        )
                      }
                      applyFunction={() => {
                        this.setState(
                          {
                            pastSessions: [],
                            liveSessions: [],
                            loading: false,
                            last: "",
                          },
                          () => this.getData("", this.state.filter)
                        );
                      }}
                      resetFunction={() => {
                        this.setState({
                          filter: {
                            session_id: "",
                            room_id: "",
                          },
                        });
                      }}
                      loading={this.state.loading}
                    />
                  </>
                )}
              </div>
              {this.props.all_templates.length ? (
                <TabGroup
                  activeTab={this.state.activeTab}
                  setActiveTab={value => {
                    AppAnalytics.track("session_tab.switched", {
                      activeTab: value,
                    });
                    this.setState({ activeTab: value, last: "" });
                  }}
                  btnId={
                    activeTab === "past" ? "past.sessions" : "live.sessions"
                  }
                  componentId={`sessions.overview.page.${activeTab}`}
                  tabs={tabs}
                />
              ) : null}
            </div>

            <div>
              <TableContainer
                activeTab={this.state.activeTab}
                hasTemplates={!!this.props.all_templates.length}
                sessions={sessions}
                loading={
                  this.state.loading ||
                  this.props.fetchTemplateStatus === API_CALL_STATE.IN_PROGRESS
                }
                switchTab={() => {
                  this.setState({
                    activeTab:
                      this.state.activeTab === "past" ? "live" : "past",
                    last: "",
                  });
                }}
                isSearch={Object.values(this.state.filter).join("") !== ""}
              />
              <div
                ref={this.loadingRef}
                style={{
                  minHeight: "1px",
                  textAlign: "center",
                }}
              >
                {this.state.loading && <LoadingSpinner classes="my-5" />}
              </div>
            </div>

            <Modal
              size="lg"
              isOpen={modal_small}
              toggle={this.tog_small}
              backdrop="static"
            >
              <div className="modal-header">
                <h5 className="modal-title mt-0 h5" id="mySmallModalLabel">
                  Downloading Data...
                </h5>
              </div>
              <div className="modal-body">
                <p>
                  {this.state.email_sent &&
                    `The sessions data has been emailed to you at ${this.state.email}`}
                  {this.state.resp_loading &&
                    "Requesting servers to collect data of your sessions"}
                </p>
              </div>
              {this.state.email_sent && (
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => this.setState({ modal_small: false })}
                  >
                    OK
                  </button>
                </div>
              )}
            </Modal>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = state => {
  return {
    all_templates: state.roles.all_templates,
    fetchTemplateStatus: state.roles.fetchTemplateStatus,
  };
};

export default connect(mapStatetoProps, { fetchTemplatesData })(
  withNamespaces()(Sessions)
);
