import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { AppAnalytics } from "src/helpers";
import { AddIcon, CardWithCvcIcon } from "@100mslive/react-icons";
import {
  Box,
  Button,
  Flex,
  Loading,
  Text,
  Tooltip,
} from "@100mslive/roomkit-react";
import DebitCard from "./DebitCard";
import { BillingGraphic } from "../../assets/images";
import emptySearchStateSVG from "../../assets/images/emptySearch.svg";
import AddCardModal from "../../components/Stripe/AddCardModal";
import BillingInfoModal from "../../components/Stripe/BillingInfoModal";
import { API_CALL_STATE } from "../../constants";

function Billing() {
  const { paymentCards, customerDetails, billing } = useSelector(
    state => state.organisation
  );
  const { fetchPaymentCards: fetchPaymentCardStatus } = useSelector(
    state => state.organisation.status
  );
  const [addressDialog, setAddressDialog] = useState(false);
  const [addCardDialog, setAddCardDialog] = useState(false);
  const displayAddress = useCallback(() => {
    const { line2, city, state, country, zipCode } = customerDetails.address;
    return [line2, city, state, country, zipCode]
      .filter(item => item)
      .join(", ");
  }, [customerDetails.address]);
  return (
    <>
      <Flex css={{ mb: "$12", mt: "$13", bg: "$border_default", h: "1px" }} />
      {fetchPaymentCardStatus === API_CALL_STATE.IN_PROGRESS ? (
        <Flex
          css={{ h: "$40", w: "100%", mt: "$16" }}
          direction="column"
          justify="center"
          align="center"
        >
          <Loading size={32} />
          <Text
            css={{
              color: "$on_surface_high",
              fontWeight: "$semiBold",
              mt: "24px",
              textAlign: "center",
            }}
          >
            Loading your Billing Details...
          </Text>
        </Flex>
      ) : fetchPaymentCardStatus === API_CALL_STATE.FAILED ? (
        <Box css={{ width: "100%", mt: "96px" }}>
          <Text css={{ textAlign: "center" }}>
            <img src={emptySearchStateSVG} alt="No information to sho" />
          </Text>
          <Text variant="h5" css={{ mt: "$10", textAlign: "center" }}>
            Failed to fetch details
          </Text>
          <Text
            variant="body1"
            css={{
              fontWeight: "$regular",
              mt: "$10",
              textAlign: "center",
              c: "$on_surface_medium",
            }}
          >
            Couldn't fetch billing information at the moment. Please try again
            later!
          </Text>
        </Box>
      ) : (
        <>
          <BillingInfoModal open={addressDialog} setOpen={setAddressDialog} />
          {!customerDetails.customer_name ||
          isEmpty(customerDetails.address) ? (
            <Flex
              justify="between"
              css={{ bg: "$surface_default" }}
              className="px-14 py-20 rounded-2xl mx-auto gap-36 w-full 2xl:w-fit"
            >
              <Flex
                direction="column"
                css={{
                  maxWidth: "470px",
                  pt: "$12",
                }}
              >
                <CardWithCvcIcon className="text-primary-light w-11 h-11" />
                <Text variant="h4" css={{ mt: "$7" }}>
                  Add your billing information
                </Text>
                <Text
                  variant="body1"
                  css={{
                    c: "$on_surface_medium",
                    fontWeight: "$regular",
                    mt: "$4",
                    mb: "$12",
                  }}
                >
                  Configure billing information like company details and payment
                  methods.
                </Text>
                <Tooltip
                  disabled={billing.is_billing_enabled}
                  title={
                    <Box css={{ maxWidth: "$56" }}>
                      Something went wrong with your billing account setup.
                      Please{" "}
                      <button
                        className="text-blue-500"
                        onClick={() => {
                          window?.devrev_widget?.open();
                        }}
                      >
                        Contact us
                      </button>{" "}
                      via the chatbot in bottom right section.
                    </Box>
                  }
                  side="bottom"
                  align="center"
                >
                  <Button
                    css={{ w: "fit-content" }}
                    disabled={!billing.is_billing_enabled}
                    onClick={() => {
                      setAddressDialog(state => !state);
                      AppAnalytics.track("btn.clicked", {
                        btnId: "change.billing.information",
                        componentId: "billing.information.home",
                      });
                    }}
                  >
                    Add Billing Information
                  </Button>
                </Tooltip>
              </Flex>
              <img
                src={BillingGraphic}
                alt="BillingGraphic"
                className="w-96 h-80"
              />
            </Flex>
          ) : (
            <Flex direction="column" css={{ w: "100%", h: "100%" }}>
              {/* Billing Info */}
              <Flex justify="between" align="center">
                <Text variant="sub1" css={{ mb: "$md" }}>
                  Billing Information
                </Text>
              </Flex>
              <Flex
                direction="column"
                css={{
                  p: "$10",
                  border: "$space$px solid $border_bright",
                  bg: "$surface_default",
                  mb: "$16",
                  r: "$3",
                }}
              >
                <Flex justify="between">
                  <Text
                    variant="body2"
                    css={{
                      c: "$on_surface_high",
                      w: "25%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {customerDetails.customer_name}
                  </Text>
                  <Flex
                    as="button"
                    css={{
                      c: "$primary_bright",
                      "&:hover": { c: "$primary_default" },
                    }}
                    align="center"
                    onClick={() => {
                      setAddressDialog(state => !state);
                      AppAnalytics.track("btn.clicked", {
                        btnId: "add.billing.information",
                        componentId: "billing.information.zero.state",
                      });
                    }}
                  >
                    <Text variant="sub2" css={{ c: "inherit" }}>
                      Change
                    </Text>
                  </Flex>
                </Flex>
                {customerDetails.address.line1 && (
                  <Text
                    variant="caption"
                    css={{
                      c: "$on_surface_medium",
                      mt: "$4",
                      w: "75%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {customerDetails.address.line1}
                  </Text>
                )}
                <Text
                  variant="caption"
                  css={{
                    c: "$on_surface_medium",
                    mt: "$4",
                    w: "75%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {displayAddress()}
                </Text>
              </Flex>
              {/* Payment information */}
              <Flex direction="column">
                <Text variant="sub1" css={{ mb: "$8" }}>
                  Payment Method
                </Text>
                <div className="flex flex-wrap gap-8">
                  {!!paymentCards.length &&
                    paymentCards.map(card => (
                      <DebitCard key={card.id} card={card} />
                    ))}
                  <Flex
                    as="button"
                    onClick={() => {
                      setAddCardDialog(state => !state);
                      AppAnalytics.track("btn.clicked", {
                        btnId: "add.card",
                        componentId: "billing.information.home",
                      });
                    }}
                    css={{
                      w: "336px",
                      h: "225px",
                      border: "$space$px dashed $border_bright",
                      gap: "$2",
                      c: "$on_surface_low",
                      r: "$3",
                      "&:hover": { c: "$on_surface_low" },
                    }}
                    justify="center"
                    align="center"
                  >
                    <AddIcon width={20} height={20} />
                    <Text variant="body1" css={{ c: "inherit" }}>
                      Add card
                    </Text>
                  </Flex>
                </div>
                <AddCardModal open={addCardDialog} setOpen={setAddCardDialog} />
              </Flex>
            </Flex>
          )}
        </>
      )}
    </>
  );
}

export default Billing;
