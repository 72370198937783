import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { Flex, Loading, Text } from "@100mslive/roomkit-react";
import OrganisationDetailsForm from "./organisationDetailsForm";
import { API_CALL_STATE } from "../../constants";
import { handleNonAdminWorkspaceRoute } from "../../helpers";
import { fetchWorkspaceDetails, fetchWorkspaces } from "../../store/actions";
import { fetchOrganisationDetails } from "../../store/organisation/actions";
import { currentUser } from "../../utils";

function Organisation() {
  const dispatch = useDispatch();
  const organisation = useSelector(state => state.organisation.organisation);
  const updateOrganisationInfo = useSelector(
    state => state.organisation.status.updateOrganisationInfo
  );
  const fetchOrganisationInfo = useSelector(
    state => state.organisation.status.fetchOrganisationInfo
  );
  const [currentMember, setCurrentMember] = useState({});
  const { current } = useSelector(state => state.workspace);
  const fetchAllWorkspaces = useSelector(
    state => state.workspace?.status?.fetchAllWorkspaces
  );
  let params = new URLSearchParams(window.location.search);
  let redirect_status = params.get("redirect_status");
  const user = currentUser();

  useEffect(() => {
    if (redirect_status === "succeeded") {
      dispatch(fetchWorkspaceDetails());
    }
  }, [dispatch, redirect_status]);

  useEffect(() => {
    if (
      !isEmpty(current?.members) &&
      current?.members.length > 0 &&
      isEmpty(currentMember)
    ) {
      setCurrentMember(
        current?.members?.find(member => member.id === user.user_id)
      );
    }
  }, [current?.members, user.user_id, currentMember]);

  if (
    !currentMember?.isOrgAdmin &&
    !isEmpty(currentMember) &&
    fetchAllWorkspaces === API_CALL_STATE.DONE
  ) {
    handleNonAdminWorkspaceRoute();
  }

  useEffect(() => {
    if (updateOrganisationInfo === API_CALL_STATE.DONE) {
      dispatch(fetchWorkspaceDetails());
      dispatch(fetchOrganisationDetails());
      dispatch(fetchWorkspaces());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateOrganisationInfo]);

  useEffect(() => {
    if (
      isEmpty(organisation) &&
      fetchOrganisationInfo !== API_CALL_STATE.FAILED
    ) {
      dispatch(fetchWorkspaceDetails());
      dispatch(fetchOrganisationDetails());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisation]);

  return (
    <>
      <Flex justify="between">
        <Flex align="center" gap="4">
          <Text variant="h5" css={{ c: "$on_surface_high" }}>
            Organization Settings
          </Text>
          <Text
            variant="body1"
            css={{
              color: "$on_surface_medium",
              fontWeight: "$regular",
              "&::before": {
                content: "• ",
              },
            }}
          >
            {organisation?.Name || "Your Organization"}
          </Text>
        </Flex>
      </Flex>
      {fetchOrganisationInfo === API_CALL_STATE.IN_PROGRESS ? (
        <Flex css={{ h: "$40", w: "100%" }} justify="center" align="center">
          <Loading />
        </Flex>
      ) : (
        <OrganisationDetailsForm />
      )}
    </>
  );
}

export default Organisation;
