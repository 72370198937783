import React, { useState } from "react";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import CopyButton from "src/components/Common/CopyButton";
import OptionItem from "src/components/Common/OptionItem";
import TruncatedColumn from "src/components/TruncatedColumn/TruncatedColumn";
import { AppAnalytics, openDevrevWidget } from "src/helpers";
import {
  ArrowRightIcon,
  DoorIcon,
  InfoIcon,
  JoinIcon,
  LayoutIcon,
  ReportIcon,
  VerticalMenuIcon,
} from "@100mslive/react-icons";
import { Box, Flex, Switch, Text, Tooltip } from "@100mslive/roomkit-react";
import RoomStateLabel from "../../components/Cards/RoomStateLabel";
import Options from "../../components/Common/Options";
import Table from "../../components/Common/TableNew";
import { formattedDate } from "../../utils";
import JoinRoomModal from "../Dashboard/JoinRoomModal";

const iconStyle = { height: "20px", width: "20px", color: "inherit" };

function RoomsTableContainer({
  rooms,
  tableHeaders,
  toggleRoomStatus,
  checkTemplateExistsForRoom,
  templates,
  isReadOnlyWorkspaceUser,
}) {
  const [openJoinRoomModalSettings, setOpenJoinRoomModalSettings] = useState(
    {}
  );
  return (
    !!rooms.length && (
      <>
        <Table
          className="table table-borderless mb-0"
          tableHeaders={tableHeaders}
          noHoverEffect
          hideBottomBorders
          restrictFinalColumn
          compact
        >
          {rooms.map((room, index) => {
            const templateExists = checkTemplateExistsForRoom(
              room?.template_id
            );
            return (
              <tr key={index}>
                <td className="align-middle">
                  <Box>
                    <Link
                      to={`room-details/${room.id}`}
                      onClick={() => {
                        AppAnalytics.track("btn.clicked", {
                          btnId: "room.name",
                          componentId: "rooms.overview.page",
                          value: room.name,
                        });
                      }}
                      className={`${!index ? "coachmark-room-links" : ""} `}
                    >
                      <Text
                        variant="sm"
                        title={room.name}
                        className="truncated-long truncated"
                        css={{
                          "&:hover": { textDecoration: "underline" },
                          color: "$primary_bright",
                          fontWeight: 700,
                        }}
                      >
                        {room.name}
                      </Text>
                    </Link>
                    <Flex
                      align="center"
                      css={{ color: "$on_surface_medium", mt: "$4" }}
                    >
                      <LayoutIcon
                        height={16}
                        width={16}
                        style={{ marginRight: "4px" }}
                      />
                      <Text
                        variant="xs"
                        title={room.template}
                        className="truncated"
                        css={{ color: "$on_surface_medium" }}
                      >
                        {room.template}
                      </Text>
                    </Flex>
                  </Box>
                </td>

                <td>
                  <div className="flex gap-3 items-center whitespace-nowrap">
                    <TruncatedColumn
                      id={room.id}
                      onClick={() => {
                        AppAnalytics.track("btn.clicked", {
                          btnId: "room.id",
                          componentId: "rooms.overview.page",
                          value: room.id,
                        });
                      }}
                      link={`room-details/${room.id}`}
                      linkExist={true}
                    />
                    <CopyButton
                      value={room.id}
                      btnId="room.id"
                      componentId="rooms.overview.page"
                    />
                  </div>
                </td>
                <td>
                  <div className="flex gap-3 items-center whitespace-nowrap">
                    <TruncatedColumn
                      id={room.template_id}
                      onClick={() => {
                        AppAnalytics.track("btn.clicked", {
                          btnId: "template.id",
                          componentId: "rooms.overview.page",
                          value: room.template_id,
                        });
                      }}
                      link={`/templates/${room.template_id}`}
                      linkExist={templateExists}
                    />
                    <CopyButton
                      value={room.template_id}
                      btnId="room.id"
                      componentId="rooms.overview.page"
                    />
                  </div>
                </td>
                <td className="align-middle">
                  <Text
                    variant="sm"
                    css={{ color: "$on_surface_medium", width: "max-content" }}
                  >
                    {formattedDate(room.created_at)}
                  </Text>
                </td>
                <td className="pr-2">
                  <Flex align="center">
                    <Tooltip
                      disabled={templateExists}
                      side="bottom"
                      align="center"
                      boxCss={{ zIndex: "1" }}
                      title={
                        <Box css={{ maxWidth: "$64" }}>
                          <InfoIcon style={{ height: "16px" }} />
                          This room cannot be enabled since the template
                          associated with this room has been deleted.{" "}
                          <button
                            className="text-blue-500"
                            onClick={() => {
                              openDevrevWidget(true);
                            }}
                          >
                            Contact Support
                            <ArrowRightIcon style={{ height: "16px" }} />
                          </button>
                        </Box>
                      }
                    >
                      <Flex align="center" css={{ gap: "$md" }}>
                        <Switch
                          disabled={!templateExists || isReadOnlyWorkspaceUser}
                          onCheckedChange={() => toggleRoomStatus(room.id)}
                          checked={templateExists ? room.active : false}
                        />
                        <RoomStateLabel
                          labelText={
                            templateExists
                              ? room.active
                                ? "Enabled"
                                : "Disabled"
                              : "Disabled"
                          }
                        />
                      </Flex>
                    </Tooltip>
                    <Options
                      Icon={
                        <VerticalMenuIcon
                          width={32}
                          height={24}
                          style={{ paddingLeft: "8px" }}
                        />
                      }
                      content={[
                        {
                          data: (
                            <OptionItem
                              onClick={() => {
                                let subdomain = "";
                                if (
                                  !isEmpty(
                                    templates[room?.template_id].subdomain
                                  )
                                ) {
                                  subdomain =
                                    templates[room?.template_id].subdomain;
                                }
                                setOpenJoinRoomModalSettings({
                                  roomId: room?.id,
                                  subdomain: subdomain,
                                  templateId: room?.template_id,
                                });
                              }}
                              icon={<JoinIcon style={iconStyle} />}
                              title="Join Room"
                            />
                          ),
                          func: () => {
                            AppAnalytics.track("btn.clicked", {
                              btnId: "inspect.room",
                              componentId: "rooms.overview.page",
                              value: room.id,
                            });
                          },
                        },
                        {
                          data: (
                            <OptionItem
                              link={`/events-inspector?id=${room.id}`}
                              icon={<ReportIcon style={iconStyle} />}
                              title="Inspect Room"
                            />
                          ),
                          func: () => {
                            AppAnalytics.track("btn.clicked", {
                              btnId: "inspect.room",
                              componentId: "rooms.overview.page",
                              value: room.id,
                            });
                          },
                        },
                        {
                          data: (
                            <OptionItem
                              link={`/room-details/${room.id}`}
                              icon={<DoorIcon style={iconStyle} />}
                              title="View Room Details"
                            />
                          ),
                          func: () => {
                            AppAnalytics.track("btn.clicked", {
                              btnId: "view.room.details",
                              componentId: "rooms.overview.page",
                              value: room.id,
                            });
                          },
                        },
                      ]}
                    />
                  </Flex>
                </td>
              </tr>
            );
          })}
        </Table>
        <JoinRoomModal
          open={!isEmpty(openJoinRoomModalSettings)}
          setOpen={() => setOpenJoinRoomModalSettings({})}
          componentId="rooms.overview.page"
          roomId={openJoinRoomModalSettings?.roomId}
          subdomain={openJoinRoomModalSettings?.subdomain}
          templateId={openJoinRoomModalSettings?.templateId}
          fetchTemplates={true}
        />
      </>
    )
  );
}

export default RoomsTableContainer;
