import { useState } from "react";
import { useDispatch } from "react-redux";
import { InfoIcon, TrashIcon } from "@100mslive/react-icons";
import { Box, Flex, Text, Tooltip } from "@100mslive/roomkit-react";
import { unknownCard } from "../../assets/images";
import { DeletePaymentCardModal } from "../../components";
import Options from "../../components/Common/Options";
import { paymentsCardImages } from "../../constants";
import { patchPaymentCard } from "../../store/organisation/actions";

function DebitCard({ card }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  return (
    <Flex css={{ r: "$3", w: "336px", h: "225px" }} direction="column">
      <Flex
        css={{
          bg: "$surface_bright",
          p: "$10",
          h: "$28",
          borderTopLeftRadius: "$3",
          borderTopRightRadius: "$3",
        }}
        justify="between"
        align="center"
      >
        <img
          className="w-12 h-8"
          src={paymentsCardImages[card.brand] || paymentsCardImages["unknown"]}
          alt={card.brand || "unknown"}
        />
        {card.default ? (
          <Flex gap={4}>
            <Text
              variant="overline"
              css={{
                bg: "$primary_default",
                textAlign: "center",
                p: "$2 $4",
                r: "$0",
              }}
            >
              PRIMARY
            </Text>
            <Tooltip
              title={
                <Box css={{ w: "$48" }}>
                  You can’t delete primary card.
                  <button
                    className="text-blue-500"
                    onClick={() => {
                      window?.devrev_widget?.open();
                    }}
                  >
                    Contact us
                  </button>{" "}
                  for more information.
                </Box>
              }
              side="bottom"
              align="center"
            >
              <Box>
                <InfoIcon />
              </Box>
            </Tooltip>
          </Flex>
        ) : (
          <>
            <Options
              content={[
                {
                  data: (
                    <Flex css={{ p: "$4 $md" }}>
                      <img
                        className="mr-2 h-5 w-5"
                        src={unknownCard}
                        alt="paymentCard"
                      />
                      Make primary
                    </Flex>
                  ),
                  func: () => dispatch(patchPaymentCard(card.id)),
                },
                {
                  data: (
                    <Flex
                      css={{ c: "$alert_error_default", p: "$4 $md" }}
                      align="center"
                    >
                      <TrashIcon className="mr-2 h-5 w-5 align-middle" />
                      Delete card
                    </Flex>
                  ),
                  func: () => setOpen(true),
                },
              ]}
            />
            <DeletePaymentCardModal state={{ open, setOpen }} card={card} />
          </>
        )}
      </Flex>
      <Flex
        css={{
          bg: "$surface_default",
          p: "$10",
          height: "100%",
          borderBottomLeftRadius: "$3",
          borderBottomRightRadius: "$3",
        }}
        direction="column"
      >
        <Flex justify="between">
          {[1, 2, 3].map(() => (
            <Text>* * * *</Text>
          ))}
          {card.last4}
        </Flex>
        <Flex justify="end" css={{ mt: "$8" }}>
          <Flex direction="column">
            <Text variant="caption" css={{ c: "$on_surface_low" }}>
              Expires In
            </Text>
            <Text className="flex justify-end mt-2">{card.expiry}</Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default DebitCard;
