import React from "react";
import { useSelector } from "react-redux";
import JoinRoomForm from "src/components/JoinRoomForm";
import { RootState } from "src/store/reducers";
import { DoorIcon } from "@100mslive/react-icons";
import { Box } from "@100mslive/roomkit-react";
import { ComposableDialog } from "../../components/Modal/ComposableDialog";
interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  roomId: string;
  componentId: string;
  subdomain: string;
  templateId: string;
  openWithActiveRole?: string;
  fetchTemplates?: boolean;
}

const JoinRoomModal: React.FC<Props> = ({
  open,
  setOpen,
  componentId,
  roomId,
  subdomain,
  templateId,
  openWithActiveRole = "",
  fetchTemplates = false,
}) => {
  const room_code_disabled =
    useSelector(
      (state: RootState) => state.workspace?.current?.room_code_disabled
    ) || false;
  return (
    <ComposableDialog.Root
      open={open}
      onOpenChange={val => {
        setOpen(val);
      }}
    >
      <ComposableDialog.Portal>
        <ComposableDialog.Content
          width={room_code_disabled ? "640px" : "1000px"}
        >
          <ComposableDialog.Header
            title="Join your room"
            Icon={
              <Box css={{ w: "$12", h: "$12", c: "$primary_bright" }}>
                <DoorIcon width={32} height={32} />
              </Box>
            }
            subtitle="Select a role and choose the way you want to join your room"
          />
          <JoinRoomForm
            componentId={componentId}
            roomId={roomId}
            subdomain={subdomain}
            templateId={templateId}
            openWithActiveRole={openWithActiveRole}
            roomCodesDisabled={room_code_disabled}
            fetchTemplates={fetchTemplates}
          />
        </ComposableDialog.Content>
      </ComposableDialog.Portal>
    </ComposableDialog.Root>
  );
};

export default JoinRoomModal;
