import { Component } from "react";
import { connect } from "react-redux";
import { Container } from "reactstrap";
import { isEmpty } from "lodash";
import toastr from "src/components/Common/toastr";
import { resetCreateTemplateReducer } from "src/store/createTemplate/actions";
import { fetchRecentRooms, updateRoomDetails } from "src/store/rooms/actions";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  ExternalLinkIcon,
  InfoIcon,
  LayoutIcon,
} from "@100mslive/react-icons";
import {
  Box,
  Button,
  Dropdown,
  Flex,
  Loading,
  Text,
} from "@100mslive/roomkit-react";
import jwt_verify from "./../../helpers/token_expiry";
import AppBoarding from "./AppBoarding";
import RecentRooms from "./RecentRooms";
import Templates from "./Templates";
import UsageTable from "./UsageTable";
import {
  fetchPublicTemplates,
  fetchStrapiStarterKits,
} from "../../actions/CreateAppActions";
import {
  deletePolicyInfo,
  fetchTemplatesData,
} from "../../actions/RolesActions";
import api from "../../api";
import { firstTemplateFig } from "../../assets/images";
import { DeleteTemplateModal } from "../../components/Modal";
import CreateRoomModal from "../../components/Modal/CreateRoomModal";
import { API_CALL_STATE, ROLE_TYPES } from "../../constants";
import { AppAnalytics } from "../../helpers/analytics_helper";
import history from "../../helpers/history";
import {
  fetchUsageOverview,
  resetFetchUsageOverview,
} from "../../store/analytics/actions";
import { fetchWorkspaceDetails } from "../../store/workspace/actions";
import { currentUser, getCurrentWorkspaceID, signedUpUser } from "../../utils";

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const getMonthList = accountCreationDate => {
  const createdDate = new Date(accountCreationDate);
  createdDate.setDate(1);
  const currentDate = new Date();
  const monthList = [
    `${monthNames[createdDate.getMonth()]}-${createdDate.getFullYear()}`,
  ];

  while (createdDate.setMonth(createdDate.getMonth() + 1) < currentDate) {
    monthList.unshift(
      `${monthNames[createdDate.getMonth()]}-${createdDate.getFullYear()}`
    );
  }

  return monthList;
};

class Dashboard extends Component {
  constructor(props) {
    super(props);
    const date = new Date();
    const currentMonth = `${monthNames[date.getMonth()]}-${date.getFullYear()}`;
    this.state = {
      folderMenu: false,
      selectedMonth: currentMonth,
      currentMonth: currentMonth,
      monthList: [currentMonth],
      loading: false,
      accountCreationDate: "",
      delete_modal: {},
      stepsEnabled: false,
      monthly: {},
      starterKits: [],
      userStarterKits: [],
      openCreateNew: false,
      openDeleteDialog: false,
      selectedKit: {},
      deleteChecked: false,
      toggleJoinRoom: false,
      toggleCreateRoom: false,
      isReadOnlyWorkspaceUser: true,
    };
    this.toggleFolder = this.toggleFolder.bind(this);
    this.toggleRoomStatus = this.toggleRoomStatus.bind(this);
    this.toggleCreateNewDialog = this.toggleCreateNewDialog.bind(this);
    this.toggleCreateRoomModal = this.toggleCreateRoomModal.bind(this);
    this.toggleJoinRoomModal = this.toggleJoinRoomModal.bind(this);
    this.toggleDeleteDialog = this.toggleDeleteDialog.bind(this);
    this.toggleDeleteDialogCheckbox =
      this.toggleDeleteDialogCheckbox.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (
      !isEmpty(props.usageOverview.account_created_at) &&
      state.currentMonth === state.selectedMonth
    ) {
      return {
        monthList: getMonthList(props?.usageOverview?.account_created_at),
      };
    }
    return null;
  }

  //Toggle Folder Menus
  toggleFolder() {
    this.setState(prevState => ({
      folderMenu: !prevState.folderMenu,
    }));
  }
  toggleCreateNewDialog() {
    if (this.props.all_templates.length === 0) {
      history.push("/onboarding");
    } else {
      history.push("/create-template");
    }
  }

  toggleJoinRoomModal() {
    setTimeout(
      () =>
        this.setState(prevState => ({
          toggleJoinRoom: !prevState.toggleJoinRoom,
        })),
      300
    );
  }

  toggleCreateRoomModal() {
    this.setState(prevState => ({
      toggleCreateRoom: !prevState.toggleCreateRoom,
    }));
  }

  toggleDeleteDialog(data) {
    const starterKit = data?.starterKit;
    if (isEmpty(starterKit)) {
      this.setState({
        openDeleteDialog: false,
        selectedKit: {},
      });
    } else {
      this.setState(prevState => ({
        deleteChecked: prevState.openDeleteDialog ? true : false,
        openDeleteDialog: !prevState.openDeleteDialog,
        selectedKit: starterKit,
      }));
    }
  }

  toggleDeleteDialogCheckbox() {
    this.setState(prevState => ({
      deleteChecked: !prevState.deleteChecked,
    }));
  }

  async fetchStarterKits() {
    if (!this.props.strapiStarterKits.length) {
      this.props.fetchStrapiStarterKits();
    }
  }

  componentDidMount() {
    const selectTime = this.state.selectedMonth.split("-");
    jwt_verify();
    this.getAppDetails();
    this.fetchStarterKits();
    this.props.resetFetchUsageOverview();
    this.props.fetchRecentRooms();
    this.props.fetchUsageOverview(selectTime[0], selectTime[1]);
    this.props.fetchTemplatesData();
    this.props.resetCreateTemplateReducer();
    this.props.fetchWorkspaceDetails();
  }

  // eslint-disable-next-line complexity
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.deletePolicyStatus === API_CALL_STATE.DONE &&
      prevProps.deletePolicyStatus !== this.props.deletePolicyStatus
    ) {
      this.toggleDeleteDialog();
    }
    if (
      !isEmpty(this.props.workspaces) &&
      signedUpUser() &&
      this.props.workspaces[getCurrentWorkspaceID()]?.is_admin
    ) {
      this.props.fetchTemplatesData();
      history.push("/onboarding/v2");
    }

    if (
      this.props.fetchUsageOverviewStatus === API_CALL_STATE.DONE &&
      prevProps.fetchUsageOverviewStatus !== this.props.fetchUsageOverviewStatus
    ) {
      this.setState({
        monthly: {
          ...prevState.monthly,
          [this.state.selectedMonth]: this.props.usageOverview,
        },
      });
    }
    if (
      prevProps.fetchWorkspaceInfoStatus === API_CALL_STATE.IN_PROGRESS &&
      this.props.fetchWorkspaceInfoStatus === API_CALL_STATE.DONE
    ) {
      let isReadOnlyUser = true;
      const currentMembers = this.props.workspace?.members || [];
      for (const member of currentMembers) {
        if (member?.role === ROLE_TYPES.READ_ONLY) {
          isReadOnlyUser = true;
          break;
        } else {
          isReadOnlyUser = false;
        }
      }
      this.setState(preState => ({
        ...preState,
        isReadOnlyWorkspaceUser: isReadOnlyUser,
      }));
    }
  }

  toggleRoomStatus = id => {
    const rooms = this.props.recentRooms;
    const roomIndex = rooms.findIndex(room => room.id === id);
    this.props.updateRoomDetails(id, !rooms[roomIndex].active);
    setTimeout(() => this.props.fetchRecentRooms(), 0);
  };

  getAppDetails = async () => {
    const user = currentUser();
    if (!user) {
      history.push("/login");
    }

    if (!this.props.publicTemplates.length) {
      this.props.fetchPublicTemplates();
    }
  };

  deleteApp = async id => {
    this.setState({
      delete_modal: {
        ...this.state.delete_modal,
        [id]: false,
      },
    });
    const user = currentUser();
    if (!user) {
      history.push("/login");
    }

    const params = {
      app_id: id,
    };

    await api
      .service("dashboard")
      .post("delete-app", params)
      .then(async () => {
        this.getAppDetails();
      })
      .catch(err => {
        console.error(err);
        toastr.error(err.message);
      });
  };

  deleteTemplate = async id => {
    this.props.deletePolicyInfo(id);
  };

  // eslint-disable-next-line complexity
  render() {
    const showApps =
      this.props.publicTemplates.length &&
      this.props.strapiStarterKits.length &&
      !(this.props.fetchTemplateStatus === API_CALL_STATE.IN_PROGRESS);

    const todayDate = new Date();
    todayDate.setHours(8);
    todayDate.setMinutes(30);
    const updatedAtTime = Math.ceil(Math.abs(todayDate - new Date()) / 36e5);

    const showUsageInfoCard = !isEmpty(this?.props?.workspaces)
      ? Object.keys(this?.props?.workspaces).length > 1
      : false;
    const selectedMonth = this.state.selectedMonth.split("-");
    const selectedTemplate =
      this.props?.templates?.[this.state?.selectedKit?.id] || {};
    return (
      <>
        <div className="page-content">
          <Container fluid>
            <CreateRoomModal
              open={this.state.toggleCreateRoom}
              openChangeHandler={this.toggleCreateRoomModal}
            />
            {!showApps ? (
              <Flex
                justify="center"
                align="center"
                css={{ w: "100%", mb: "$10" }}
              >
                <Loading size={48} />
              </Flex>
            ) : this.props.all_templates.length > 0 ? (
              <Flex css={{ mb: "$12" }}>
                <RecentRooms
                  rooms={this.props.recentRooms}
                  open={this.state.toggleJoinRoom}
                  setOpen={this.toggleJoinRoomModal}
                  templates={this.props.all_templates}
                  toggleCreateRoomModal={this.toggleCreateRoomModal}
                  isRoomLoading={
                    this.props.fetchRecentRoomsStatus ===
                    API_CALL_STATE.IN_PROGRESS
                  }
                />
                <Templates
                  clickFunction={this.toggleCreateNewDialog}
                  templates={this.props.all_templates.sort(template =>
                    template.id === this.props.workspace.DefaultTemplateID
                      ? -1
                      : 1
                  )}
                  defaultTemplateId={this.props.workspace.DefaultTemplateID}
                  toggleDelete={this.toggleDeleteDialog}
                  templatesLoading={
                    this.props.fetchTemplateStatus ===
                    API_CALL_STATE.IN_PROGRESS
                  }
                />
              </Flex>
            ) : (
              <Box
                css={{
                  bg: "$surface_default",
                  mb: "$14",
                  r: "$3",
                  py: "$16",
                }}
              >
                <Flex
                  css={{ gap: "$32" }}
                  justify="between"
                  className="px-14"
                  align="center"
                >
                  <Box>
                    <LayoutIcon className="text-primary-light w-12 h-12 relative -left-1.5" />
                    <Text
                      variant="h5"
                      css={{
                        fontWeight: "$semiBold",
                        c: "$on_surface_high",
                        mt: "$8",
                        fontFamily: "Inter",
                      }}
                    >
                      Create your first room with a template
                    </Text>
                    <Text
                      variant="body2"
                      css={{
                        fontWeight: "$regular",
                        c: "$on_surface_medium",
                        mt: "$4",
                        mb: "$12",
                      }}
                    >
                      Click <span className="font-semibold">Create a Room</span>{" "}
                      to get started.
                    </Text>
                    <Button
                      disabled={this.state.isReadOnlyWorkspaceUser}
                      css={{ mb: "$8" }}
                      onClick={() => {
                        AppAnalytics.track("btn.clicked", {
                          btnId: "create.first.app",
                          componentId: "dashboard",
                          platform: "hms-dashboard",
                          starterKitName: "custom app",
                        });
                        history.push("/onboarding");
                        //this.toggleCreateNewDialog();
                      }}
                    >
                      Create a Room
                    </Button>

                    <a
                      className="text-textIcon-medEmp font-normal"
                      target="_blank"
                      rel="noreferrer"
                      href={`${process.env.REACT_APP_WEBSITE_URL}docs/javascript/v2/foundation/basics#basic-concepts`}
                      onClick={() =>
                        AppAnalytics.track("link.clicked", {
                          componentId: "home",
                        })
                      }
                    >
                      Learn more about Rooms & Templates
                      <ExternalLinkIcon className="w-3 h-3 ml-2 mb-px" />
                    </a>
                  </Box>

                  <img
                    fetchpriority="high"
                    rel="preload"
                    src={firstTemplateFig}
                    alt="firstTemplateFigure"
                    style={{
                      maxWidth: "465px",
                      width: "60%",
                      height: "auto",
                    }}
                  />
                </Flex>
              </Box>
            )}

            <DeleteTemplateModal
              checked={this.state.deleteChecked}
              toggleCheckBox={this.toggleDeleteDialogCheckbox}
              disableButton={!this.state.deleteChecked}
              data={{
                title: selectedTemplate?.name,
                subtitle: selectedTemplate?.subdomain_name,
              }}
              open={this.state.openDeleteDialog}
              deleteFunction={() => {
                this.deleteTemplate(selectedTemplate.id);
              }}
              toggleDeleteDialog={this.toggleDeleteDialog}
            />

            <AppBoarding />

            <div>
              <Box
                css={{
                  py: "$12",
                  px: "$12",
                  border: "$border_default solid $space$px",
                  borderRadius: "$1",
                }}
              >
                <div className="d-flex justify-content-between items-center mb-4">
                  <Text variant="h6">Usage Overview</Text>
                  <Flex align="center" gap="3">
                    <Text
                      variant="body2"
                      css={{ c: "$on_surface_low", fontWeight: "$regular" }}
                    >
                      Updated {updatedAtTime}h ago
                    </Text>
                    <Dropdown.Root
                      open={this.state.folderMenu}
                      css={{ cursor: "pointer" }}
                      onOpenChange={() => {
                        if (
                          this.props.fetchUsageOverviewStatus !==
                          API_CALL_STATE.IN_PROGRESS
                        ) {
                          this.toggleFolder();
                        }
                      }}
                    >
                      <Dropdown.Trigger
                        asChild
                        css={{
                          bg: "$surface_default !important",
                          border: "$space$px solid $border_bright",
                          r: "$1",
                          p: "$4 $8",
                          "&:hover": {
                            bg: "$surfaceDark !important",
                          },
                        }}
                      >
                        <Flex
                          css={{
                            bg: "$surface_default !important",
                            border: "$space$px solid $border_bright",
                            r: "$1",
                            p: "$4 $8",
                            "&:hover": {
                              bg: "$surfaceDark !important",
                            },
                          }}
                          align="center"
                        >
                          <span className="mr-2">
                            {`${selectedMonth[0]} ${selectedMonth[1]}`}
                          </span>
                          {this.state.folderMenu ? (
                            <ChevronUpIcon size={16} />
                          ) : (
                            <ChevronDownIcon size={16} />
                          )}
                        </Flex>
                      </Dropdown.Trigger>
                      <Dropdown.Content
                        side="bottom"
                        align="end"
                        sideOffset={10}
                        css={{ w: "$40" }}
                      >
                        {this.state.monthList.map(month => (
                          <Dropdown.Item
                            to="#"
                            active={month === this.state.selectedMonth}
                            key={month}
                            onClick={() => {
                              AppAnalytics.track("month_filter.clicked", {
                                selected_month: month,
                                current_month: this.state.selectedMonth,
                              });
                              this.setState(
                                {
                                  selectedMonth: month,
                                },
                                () => {
                                  if (isEmpty(this.state.monthly[month])) {
                                    const selectTime = month.split("-");

                                    this.props.fetchUsageOverview(
                                      selectTime[0],
                                      selectTime[1]
                                    );
                                  }
                                  this.toggleFolder();
                                }
                              );
                            }}
                            css={{ p: "$4 $md" }}
                          >
                            {`${month.split("-")[0]} ${month.split("-")[1]}`}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Content>
                    </Dropdown.Root>
                  </Flex>
                </div>
                {showUsageInfoCard ? (
                  <>
                    <Flex
                      css={{
                        w: "100%",
                        p: "$8 $12",
                        bg: "$surface_default",
                        r: "$1",
                      }}
                    >
                      <Flex css={{ c: "$primary_bright", mr: "$8" }}>
                        <InfoIcon />
                      </Flex>
                      <Text variant="body1" css={{ fontWeight: "$regular" }}>
                        The usage overview represents the whole organisation,
                        not the single workspace
                      </Text>
                    </Flex>
                    <Flex
                      css={{
                        bg: "$border_default",
                        h: "1px",
                        w: "100%",
                        my: "$12",
                      }}
                    />
                  </>
                ) : null}
                <UsageTable
                  data={this.state.monthly[this.state.selectedMonth]}
                  loading={
                    this.props.fetchUsageOverviewStatus ===
                    API_CALL_STATE.IN_PROGRESS
                  }
                />
              </Box>
            </div>
          </Container>
        </div>
      </>
    );
  }
}

const mapStatetoProps = state => {
  const { roles } = state;
  return {
    all_templates: roles.all_templates,
    templates: roles.templates,
    publicTemplates: state.createApp.publicTemplates,
    strapiStarterKits: state.createApp.strapiStarterKits,
    deletePolicyStatus: roles.deletePolicyStatus,
    workspace: state.workspace.current,
    fetchTemplateStatus: roles.fetchTemplateStatus,
    workspaces: state.workspace.workspaces,
    fetchUsageOverviewStatus: state.roomDebugger.fetchUsageOverviewStatus,
    usageOverview: state.roomDebugger.usageOverview,
    addNewAppStatus: state.createTemplate.addNewAppStatus,
    roomId: state.createTemplate.roomId,
    createdTemplate: state.createTemplate.createdTemplate,
    recentRooms: state.rooms.recentRooms,
    updateRoomStatus: state.rooms.updateRoomStatus,
    fetchRecentRoomsStatus: state.rooms.fetchRecentRoomsStatus,
    roleLayouts: state.appLayouts.roleLayouts,
    fetchWorkspaceInfoStatus: state?.workspace?.status?.fetchWorkspaceInfo,
  };
};

export default connect(mapStatetoProps, {
  fetchPublicTemplates,
  fetchStrapiStarterKits,
  deletePolicyInfo,
  fetchUsageOverview,
  resetFetchUsageOverview,
  fetchTemplatesData,
  fetchRecentRooms,
  updateRoomDetails,
  resetCreateTemplateReducer,
  fetchWorkspaceDetails,
})(Dashboard);
