import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { regionMap, validationMessagesKeys } from "src/constants";
import { EditIcon, TextFormatIcon } from "@100mslive/react-icons";
import { Button, Flex, Text } from "@100mslive/roomkit-react";
import Credential from "../../components/Common/Credential";
import { AppAnalytics } from "../../helpers";
import { updateWorkspaceDetails } from "../../store/workspace/actions";
import { currentUser } from "../../utils";
import { Messages } from "../../validations";

function WorkspaceDetailsForm() {
  const dispatch = useDispatch();
  const { current: workspace, workspaces } = useSelector(
    state => state.workspace
  );
  const [workspaceDetails, setWorkspaceDetails] = useState({});
  const [invalidName, setInvalidName] = useState(false);
  useEffect(() => {
    setWorkspaceDetails(workspace);
  }, [workspace]);
  const user = currentUser();

  const validityArray = Object.values(workspaces)
    .filter(workspace => user.customer_id === workspace.customer_id)
    .map(workspace => workspace.name);

  useEffect(() => {
    if (!isEmpty(workspaces) && workspace.name !== workspaceDetails.name) {
      setInvalidName(
        Messages(
          validationMessagesKeys.validWorkspaceName,
          workspaceDetails.name,
          false,
          validityArray
        )
      );
    } else {
      setInvalidName(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceDetails]);

  return (
    <Flex direction="column" css={{ w: "$96" }}>
      <Text variant="h6">Details</Text>
      <Text variant="body2" css={{ mt: "$12" }}>
        Workspace ID
      </Text>
      <Credential
        css={{ mt: "$4" }}
        readOnly={true}
        showClipboard={true}
        disabled={true}
        label="Workspace ID"
        onChangeHandler={() => {
          return;
        }}
        hideLabel
        leftIcon={<TextFormatIcon />}
        value={workspaceDetails?.id}
        title=""
      />
      <Text
        variant="caption"
        className="mt-2"
        css={{ color: "$on_surface_medium", mt: "$4" }}
      >
        Workspace ID cannot be edited
      </Text>
      <Text variant="body2" css={{ mt: "$9", mb: "$4" }}>
        Workspace Name
      </Text>
      <Credential
        readOnly={false}
        showClipboard={false}
        label="Workspace Name"
        hideLabel
        leftIcon={<EditIcon />}
        value={workspaceDetails?.name}
        title=""
        error={!!invalidName}
        placeholder="e.g. Staging or QA"
        onChangeHandler={value => {
          setWorkspaceDetails({ ...workspaceDetails, name: value });
        }}
      />
      <Flex
        css={{
          visibility: invalidName ? "visible" : "hidden",
          maxHeight: invalidName ? "100%" : "0",
        }}
      >
        <Text
          variant="caption"
          css={{
            fontWeight: "$regular",
            c: "$alert_error_default",
            mt: "$4",
          }}
        >
          {invalidName}
        </Text>
      </Flex>
      <Text variant="body2" css={{ mt: "$12" }}>
        Data Residency Region
      </Text>
      <Credential
        css={{ mt: "$4" }}
        readOnly={true}
        showClipboard={false}
        disabled={true}
        label="Workspace ID"
        onChangeHandler={() => {
          return;
        }}
        hideLabel
        value={regionMap[workspaceDetails?.store || "in"]}
        title=""
      />
      <Text
        variant="caption"
        className="mt-2"
        css={{ color: "$on_surface_medium", mt: "$4" }}
      >
        Region cannot be changed
      </Text>
      <Flex gap="4" css={{ mt: "$16" }}>
        <Button
          outlined
          variant="standard"
          disabled={workspaceDetails.name === workspace.name || invalidName}
          onClick={() => setWorkspaceDetails(workspace)}
        >
          Discard
        </Button>
        <Button
          disabled={
            workspaceDetails.name === workspace.name ||
            isEmpty(workspaceDetails.name) ||
            invalidName
          }
          onClick={() => {
            AppAnalytics.track("btn.clicked", {
              btnId: "settings.workspace",
              componentId: "form",
              previousWorkspaceName: workspace?.name,
              renamedWorkspaceName: workspaceDetails?.name,
            });
            dispatch(
              updateWorkspaceDetails({
                ...workspaceDetails,
                name: workspaceDetails.name.trim(),
              })
            );
          }}
        >
          Save changes
        </Button>
      </Flex>
    </Flex>
  );
}

export default WorkspaceDetailsForm;
