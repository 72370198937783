import React, { ComponentProps, PropsWithChildren, ReactNode } from "react";
import { textVariants } from "src/types/common";
import { Box, Flex } from "@100mslive/roomkit-react";
import SettingsTitle from "../Template/SettingsTitle";
interface SidebarItemProps {
  title: string;
  tooltipText?: ReactNode;
  titleVariant?: textVariants;
  hideTooltip?: boolean;
  isSwitchTitle?: boolean;
}

function PrebuiltSidebarItemWrapper({
  title,
  tooltipText = "",
  titleVariant = "xs",
  hideTooltip = false,
  isSwitchTitle = false,
  children,
  ...props
}: PropsWithChildren<ComponentProps<typeof Box> & SidebarItemProps>) {
  return (
    <Box
      css={{
        my: "$8",
        ...props.css,
      }}
      {...props}
    >
      <Flex
        align="center"
        css={{
          gap: "$2",
          color: "$on_surface_low",
          mb: isSwitchTitle ? 0 : "$4",
        }}
      >
        <SettingsTitle
          text={title}
          titleVariant={titleVariant}
          disabled={!tooltipText}
          hideTooltip={hideTooltip}
          textCSS={{ fontWeight: "$regular" }}
          title={!hideTooltip ? tooltipText : ""}
        />
      </Flex>
      {children}
    </Box>
  );
}

export default PrebuiltSidebarItemWrapper;
