import React, { FC, ReactNode, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { EditTemplateNameModal } from "src/components";
import TemplateCard from "src/components/Cards/TemplateCard";
import { Pagination } from "src/components/Common/Pagination";
import { ROLE_TYPES } from "src/constants";
import { AppAnalytics } from "src/helpers";
import { RootState } from "src/store/reducers";
import { getEmailfromLocal } from "src/utils";
import { AddCircleIcon } from "@100mslive/react-icons";
import { Button, Flex, Text } from "@100mslive/roomkit-react";

type TemplatesPropTypes = {
  clickFunction: () => unknown;
  toggleDelete?: () => unknown;
  templates: ReactNode[];
  defaultTemplateId: string;
  templatesLoading?: boolean;
};
const Templates: FC<TemplatesPropTypes> = ({
  clickFunction,
  toggleDelete,
  templates,
  defaultTemplateId,
  templatesLoading = false,
}) => {
  const [page, setPage] = useState<number>(0);
  const [templateToBeEdited, setTemplateToBeEdited] = useState("");
  const numOfPages = Math.ceil(templates?.length / 3);
  const [triggerEditTemplate, setTriggerEditTemplate] = useState(false);
  const nextPage = Math.min((page + 1) * 3, templates?.length);
  const current = useSelector((state: RootState) => state.workspace.current);
  const [isReadOnlyWorkspaceUser, setIsReadOnlyWorkspaceUser] = useState(false);

  useEffect(() => {
    let isReadOnlyUser = true;
    const currentMembers = current.members || [];
    const currentUserEmail = getEmailfromLocal();
    for (const member of currentMembers) {
      if (
        currentUserEmail === member?.email &&
        member?.role === ROLE_TYPES.READ_ONLY
      ) {
        isReadOnlyUser = true;
        break;
      } else {
        isReadOnlyUser = false;
      }
    }
    setIsReadOnlyWorkspaceUser(isReadOnlyUser);
  }, [current]);
  return (
    <Flex
      css={{
        border: "$space$px solid $border_default",
        r: "$1",
        overflow: "auto",
        maxHeight: "100%",
        w: "50%",
        p: "$12",
        pb: templates?.length > 3 ? "$8" : "$12",
      }}
      direction="column"
    >
      <EditTemplateNameModal
        policyId={templateToBeEdited}
        hideButton
        shouldFetchPolicy={true}
        trigger={triggerEditTemplate}
        setTrigger={setTriggerEditTemplate}
      />
      <Flex justify="between" align="center">
        <Text variant="h6">Templates</Text>
        <Button
          variant="primary"
          css={{ fontSize: "$sm", borderRadius: "$0" }}
          icon
          disabled={isReadOnlyWorkspaceUser}
          onClick={() => {
            if (templates.length === 0) {
              AppAnalytics.track("btn.clicked", {
                btnId: "create.first.app",
                componentId: "dashboardApp",
                platform: "hms-dashboard",
              });
            } else {
              AppAnalytics.track("btn.clicked", {
                btnId: "create.app",
                componentId: "dashboardApp",
                platform: "hms-dashboard",
              });
              AppAnalytics.track("btn.clicked", {
                btnId: "create.template.clicked",
                componentId: "dashboardHome",
              });
            }
            clickFunction();
          }}
        >
          <AddCircleIcon />
          Create Template
        </Button>
      </Flex>

      {templates?.length && !templatesLoading ? (
        <>
          {templates.slice(page * 3, nextPage).map(
            //eslint-disable-next-line
            (template: any) => (
              <Flex key={template.id} css={{ mt: "$8" }}>
                <TemplateCard
                  template={template}
                  isDefaultTemplate={template._id === defaultTemplateId}
                  toggleDelete={toggleDelete}
                  setTemplateToBeEdited={() => {
                    setTemplateToBeEdited(template._id);
                    setTriggerEditTemplate(true);
                  }}
                />
              </Flex>
            )
          )}
          {templates?.length > 3 ? (
            <Flex
              justify="between"
              align="center"
              css={{
                mt:
                  templates.slice(page * 3, nextPage).length < 3
                    ? "auto"
                    : "$6",
                w: "100%",
              }}
            >
              <Text
                variant="overline"
                css={{ c: "$on_surface_low", w: "100%" }}
              >{`${templates.length} TEMPLATES`}</Text>
              <Flex justify="end" align="center" css={{ w: "100%" }}>
                <Pagination
                  page={page}
                  setPage={setPage}
                  numPages={numOfPages}
                />
              </Flex>
            </Flex>
          ) : null}
        </>
      ) : null}
    </Flex>
  );
};

export default Templates;
