import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ROLE_TYPES } from "src/constants";
import { history } from "src/helpers";
import { getEmailfromLocal } from "src/utils";
import { DoorIcon, ExternalLinkIcon } from "@100mslive/react-icons";
import { Box, Button, Flex, Text } from "@100mslive/roomkit-react";
import { firstTemplateFig } from "../../assets/images";
import { AppAnalytics } from "../../helpers/analytics_helper";

const ZeroState = ({ subtitle, componentId = "", hasTemplates = false }) => {
  const { current } = useSelector(state => state.workspace);
  const [isReadOnlyWorkspaceUser, setIsReadOnlyWorkspaceUser] = useState(true);

  useEffect(() => {
    let isReadOnlyUser = true;
    const currentMembers = current.members || [];
    const currentUserEmail = getEmailfromLocal();
    for (const member of currentMembers) {
      if (
        currentUserEmail === member?.email &&
        member?.role === ROLE_TYPES.READ_ONLY
      ) {
        isReadOnlyUser = true;
        break;
      } else {
        isReadOnlyUser = false;
      }
    }
    setIsReadOnlyWorkspaceUser(isReadOnlyUser);
  }, [current]);
  return (
    <Box>
      {subtitle}
      <Box
        css={{
          bg: "$surface_default",
          mb: "$14",
          r: "$3",
          py: "$9",
        }}
      >
        <Flex
          css={{ gap: "$32", mt: "$8" }}
          justify="between"
          className="px-14"
          align="center"
        >
          <Box css={{ py: "$20" }}>
            <DoorIcon className="text-primary-light w-12 h-12 relative -left-1.5" />
            <Text
              variant="h5"
              css={{
                fontWeight: "$semiBold",
                c: "$on_surface_high",
                mt: "$12",
              }}
            >
              Create your first room
            </Text>
            <Text
              variant="body2"
              css={{
                fontWeight: "$regular",
                c: "$on_surface_medium",
                mt: "$4",
                fontFamily: "Inter",
                mb: "$12",
              }}
            >
              Click <span className="font-semibold">Create a Room</span> to get
              started.
            </Text>
            <Button
              disabled={isReadOnlyWorkspaceUser}
              css={{ borderRadius: "4px", mb: "$8" }}
              variant="primary"
              onClick={() => {
                if (!hasTemplates) {
                  AppAnalytics.track("btn.clicked", {
                    btnId: "create.first.app",
                    componentId,
                    platform: "hms-dashboard",
                  });

                  history.push("/onboarding");
                } else {
                  AppAnalytics.track("btn.clicked", {
                    btnId: "create.app",
                    componentId,
                    platform: "hms-dashboard",
                  });
                  history.push("/create-template");
                }
              }}
            >
              Create a Room
            </Button>
            <a
              className="text-textIcon-medEmp font-normal"
              target="_blank"
              rel="noreferrer"
              href={`${process.env.REACT_APP_WEBSITE_URL}docs/javascript/v2/foundation/basics#basic-concepts`}
              onClick={() =>
                AppAnalytics.track("link.clicked", {
                  componentId: "rooms",
                })
              }
            >
              Learn more about Rooms & Templates
              <ExternalLinkIcon className="w-3 h-3 ml-2 mb-px" />
            </a>
          </Box>
          <Flex>
            <img
              fetchpriority="high"
              rel="preload"
              src={firstTemplateFig}
              alt="firstTemplateFigure"
              style={{
                maxWidth: "500px",
                width: "100%",
                marginLeft: "auto",
                height: "auto",
              }}
            />
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};

export default ZeroState;
