import { useEffect } from "react";
import queryString from "query-string";
import { createGetURL } from "src/helpers";
import { getUtmParams } from "src/utils";
import { Loading } from "@100mslive/roomkit-react";
function randomString(length) {
  var charset =
    "0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz+/";
  let result = "";

  while (length > 0) {
    var bytes = new Uint8Array(16);
    var random = window.crypto.getRandomValues(bytes);

    // eslint-disable-next-line no-loop-func
    random.forEach(c => {
      if (length === 0) {
        return;
      }
      if (c < charset.length) {
        result += charset[c];
        length--;
      }
    });
  }
  return result;
}

function WebsiteGoogleRegistration() {
  const handleGoogleLogin = function () {
    const baseURL = "https://accounts.google.com/o/oauth2/v2/auth";
    const params = {
      response_type: "id_token permission",
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      scope: "openid email profile",
      redirect_uri: `${process.env.REACT_APP_HOST_URL}register`,
      nonce: randomString(10),
      state: encodeURIComponent(
        JSON.stringify({
          ...getUtmParams(),
          ...queryString.parse(window.location?.search),
        })
      ),
    };

    window.location.href = createGetURL(baseURL, params);
  };
  useEffect(() => {
    handleGoogleLogin();
  }, []);
  return <Loading size={48} />;
}

export default WebsiteGoogleRegistration;
