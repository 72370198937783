import React from "react";
import { JsonView } from "json-view-for-react";
import _ from "lodash";
import moment from "moment";
import InfoCard from "src/components/Cards/InfoCard";
import { Box, Flex, Text } from "@100mslive/roomkit-react";
import paragraph from "../../assets/images/paragraph.png";
import "./eventDetails.scss";

function EventDetails({ event, showDisclaimer, setShowDisclaimer }) {
  const date = new Date(event.timestamp);
  return !_.isEmpty(event) ? (
    <Flex
      direction="column"
      css={{
        w: "100%",
      }}
    >
      <Flex
        direction="column"
        css={{
          border: "1px solid $border_default",
          bg: "$surface_dim",
          borderRadius: "$0",
          mt: "$2",
        }}
      >
        <Flex
          css={{
            w: "100%",
            h: "3.5rem",
            borderTopRightRadius: "$0",
            borderTopLeftRadius: "$0",
            bg: "$surface_bright",
            p: "$8 $12",
          }}
          justify="between"
          align="center"
        >
          <Text
            variant="sm"
            css={{
              c: "$on_surface_high",
              fontWeight: "$semiBold",
            }}
          >
            {event.type}
          </Text>
          <Text
            variant="xs"
            css={{
              c: "$on_surface_medium",
              fontWeight: "$regular",
            }}
          >
            {moment(date).format("MMM D YYYY, HH:mm:ss")}
          </Text>
        </Flex>
        <hr></hr>
        <Box
          css={{
            overflow: "auto",
            h: "100%",
            pt: "$10",
            pl: "$12",
            width: "100%",
          }}
        >
          <JsonView obj={event} cssPrefix="jsonview" />
        </Box>
      </Flex>
      <Flex css={{ mt: "$4" }}>
        <InfoCard
          fullWidth
          btnText="Read More"
          open={showDisclaimer}
          setOpen={() => setShowDisclaimer(!setShowDisclaimer)}
          componentId="template.config"
          title=""
          body="Viewing the complete payload is not currently supported. To access the full payload, configure webhooks"
          link={`${process.env.REACT_APP_WEBSITE_URL}docs/server-side/v2/how-to-guides/configure-webhooks/overview`}
        />
      </Flex>
    </Flex>
  ) : (
    <Flex
      css={{ w: "100%" }}
      direction="column"
      justify="center"
      align="center"
    >
      <img src={paragraph} alt="100ms" width="40px" height="40px"></img>
      <Text
        css={{
          textAlign: "center",
          mt: "$md",
        }}
        variant="body1"
      >
        No event selected
      </Text>
      <Text
        css={{
          w: "min(300px, 100%)",
          textAlign: "center",
          mt: "$xs",
          c: "$on_surface_medium",
        }}
        variant="body2"
      >
        Select an event in the list to the left to get started.
      </Text>
    </Flex>
  );
}

export default EventDetails;
