import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import {
  BillIcon,
  BuildingIcon,
  EditIcon,
  MoneyIcon,
  PersonIcon,
} from "@100mslive/react-icons";
import { Button, Flex, Text } from "@100mslive/roomkit-react";
import Billing from "./billing";
import BillingTable from "../../components/Billing/BillingTable";
import Credential from "../../components/Common/Credential";
import TabGroup from "../../components/Common/TabGroup";
import { API_CALL_STATE, validationMessagesKeys } from "../../constants";
import { AppAnalytics } from "../../helpers";
import {
  FETCH_BILLING_RESET,
  fetchCustomerDetails,
  fetchOrganisationDetails,
  fetchPaymentCards,
  updateOrganisationDetails,
} from "../../store/organisation/actions";
import { Messages } from "../../validations";

const tabs = [
  {
    name: "Organization Details",
    id: 0,
    icon: <BuildingIcon />,
    title: "details",
  },
  {
    name: "Invoices",
    id: 1,
    icon: <BillIcon />,
    title: "invoices",
  },
  { name: "Billing Information", id: 2, icon: <MoneyIcon />, title: "billing" },
];

function OrganisationDetailsForm() {
  const dispatch = useDispatch();

  const organisation = useSelector(state => state.organisation.organisation);

  const { updateOrganisationInfo, fetchOrganisationInfo } = useSelector(
    state => state.organisation.status
  );
  const [organisationDetails, setOrganisationDetails] = useState({});
  const [activeTab, setActiveTab] = useState(
    (window.location.search &&
      new URLSearchParams(window.location.search).get("tab")) ||
      tabs[0]?.title
  );
  const [invalidName, setInvalidName] = useState(false);
  useEffect(() => {
    if (!isEmpty(organisation)) {
      setOrganisationDetails(organisation);
    } else {
      if (API_CALL_STATE.FAILED !== fetchOrganisationInfo) {
        dispatch(fetchOrganisationDetails());
      }
    }
  }, [organisation, fetchOrganisationInfo, dispatch]);

  useEffect(() => {
    setInvalidName(
      Messages(
        validationMessagesKeys.validOrganisationName,
        organisationDetails.Name
      )
    );
  }, [organisationDetails]);

  useEffect(() => {
    dispatch(fetchPaymentCards());
    dispatch(fetchCustomerDetails());
  }, [dispatch]);

  useEffect(() => () => dispatch({ type: FETCH_BILLING_RESET }), [dispatch]);

  return (
    <Flex direction="column" className="mt-6">
      <TabGroup
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        btnId={`${activeTab}.tab`}
        componentId="organisations"
      />
      {!!(activeTab === tabs[0].title) && (
        <>
          <Flex
            css={{ mb: "$12", mt: "$13", bg: "$border_default", h: "1px" }}
          />
          <Text variant="h6">Organization Details</Text>
          <Text variant="body2" css={{ mt: "$12" }}>
            Customer ID
          </Text>
          <Credential
            css={{ mt: "$4", w: "$96" }}
            readOnly={true}
            showClipboard={true}
            disabled={true}
            label="Workspace ID"
            onChangeHandler={() => {
              return;
            }}
            hideLabel
            leftIcon={<PersonIcon />}
            value={organisationDetails?.ID}
            title=""
          />
          <Text
            variant="caption"
            className="mt-2"
            css={{ color: "$on_surface_medium", mt: "$4" }}
          >
            Customer ID can only be copied
          </Text>
          <Text variant="body2" css={{ mt: "$9", mb: "$4" }}>
            Organization Name
          </Text>
          <Credential
            css={{ w: "$96" }}
            readOnly={false}
            showClipboard={false}
            label="Enter Organization Name"
            hideLabel
            leftIcon={<EditIcon />}
            value={organisationDetails?.Name}
            title=""
            error={!!invalidName}
            placeholder="e.g. 100ms Inc"
            onChangeHandler={value => {
              setOrganisationDetails({ ...organisationDetails, Name: value });
            }}
          />
          <Flex
            css={{
              visibility: invalidName ? "visible" : "hidden",
              maxHeight: invalidName ? "100%" : "0",
            }}
          >
            <Text
              variant="caption"
              css={{
                fontWeight: "$regular",
                c: "$alert_error_default",
                mt: "$4",
              }}
            >
              {invalidName}
            </Text>
          </Flex>
          <Flex gap="4" css={{ mt: "$16" }}>
            <Button
              outlined
              variant="standard"
              disabled={
                organisationDetails.Name === organisation.Name || invalidName
              }
              onClick={() => setOrganisationDetails(organisation)}
            >
              Discard
            </Button>
            <Button
              disabled={
                organisationDetails.Name === organisation.Name || invalidName
              }
              loading={updateOrganisationInfo === API_CALL_STATE.IN_PROGRESS}
              onClick={() => {
                AppAnalytics.track("btn.clicked", {
                  btnId: "settings.organization",
                  componentId: "form",
                  previousOrganizationName: organisation?.Name,
                  renamedOrganizationName: organisationDetails?.Name,
                });
                dispatch(
                  updateOrganisationDetails({
                    ...organisationDetails,
                    Name: organisationDetails.Name.trim(),
                  })
                );
              }}
            >
              Save changes
            </Button>
          </Flex>
        </>
      )}
      {!!(activeTab === tabs[1].title) && <BillingTable />}
      {!!(activeTab === tabs[2].title) && <Billing />}
    </Flex>
  );
}

export default OrganisationDetailsForm;
