import { currentWorkspaceHipaa } from "src/utils";
import {
  ArrowRightIcon,
  BoxIcon,
  BrushDesignIcon,
  GraduationIcon,
} from "@100mslive/react-icons";
import { Box, Flex, Text } from "@100mslive/roomkit-react";
import shieldCheckPrimaryLight from "../../assets/icons/shieldCheckPrimaryLight.svg";
import Tag from "../../components/Tag/Tag";
import { AppAnalytics } from "../../helpers/analytics_helper";

const AppBoarding = () => {
  const cardsData = [
    {
      icon: (
        <img
          src={shieldCheckPrimaryLight}
          alt="Shield Check"
          style={{ height: "24px", width: "24px" }}
        />
      ),
      title: "HIPAA Compliance",
      subText:
        "Learn more about 100ms' HIPAA compliant workspace and how we've accomplished that.",
      linkText: "Read HIPAA Document",
      link: `${process.env.REACT_APP_WEBSITE_URL}docs/get-started/v2/get-started/security-and-privacy/HIPAA%20compliance/HIPAA-workspace`,
      eventName: "examples_link.clicked",
      show: currentWorkspaceHipaa(),
    },
    {
      icon: <BrushDesignIcon />,
      title: "Prebuilt",
      isNew: true,
      subText:
        "Say hello to no-code. Customize and build your UI without leaving the dashboard",
      linkText: "Learn more",
      link: `${process.env.REACT_APP_WEBSITE_URL}docs/get-started/v2/get-started/prebuilt/overview`,
      eventName: "examples_link.clicked",
      show: !currentWorkspaceHipaa(),
    },
    {
      icon: <BoxIcon />,
      title: "Fundamentals",
      subText:
        "Learn the core fundamentals and architecture before getting started.",
      linkText: "Learn Fundamentals",
      link: `${process.env.REACT_APP_WEBSITE_URL}docs/concepts/v2/concepts/basics`,
      eventName: "guides_link.clicked",
      show: true,
    },
    {
      icon: <GraduationIcon />,
      title: "Get Started",
      subText:
        "We've curated guides and examples to help you code up a working app using 100ms SDK",
      linkText: "Browse Examples",
      link: `${process.env.REACT_APP_WEBSITE_URL}docs/examples`,
      eventName: "examples_link.clicked",
      show: true,
    },
  ];
  return (
    <Box
      css={{
        border: "1px solid $border_default",
        p: "$12",
        mb: "$12",
        borderRadius: "$1",
      }}
    >
      <Text variant="h5" css={{ color: "$on_surface_high" }}>
        Quick Links
      </Text>
      <Text variant="sm" css={{ c: "$on_surface_medium" }}>
        Get started by accessing the following resources
      </Text>
      <Flex align="stretch" justify="between" css={{ mt: "$10", gap: "$12" }}>
        {cardsData.map(cardData => {
          return (
            cardData.show === true && (
              <Flex
                direction="column"
                key={cardData.title}
                css={{
                  backgroundColor: "$surface_default",
                  border: "1px solid $border_default",
                  borderRadius: "$0",
                  p: "$9 $10",
                  color: "$primary_bright",
                  flexGrow: "1",
                  w: "33%",
                }}
              >
                {cardData.icon}
                <Flex
                  direction="column"
                  justify="between"
                  css={{ flexGrow: "1" }}
                >
                  <Flex direction="column">
                    <Flex>
                      <Text
                        variant="h6"
                        css={{ color: "$on_surface_high", mt: "$6" }}
                      >
                        {cardData.title}
                      </Text>
                      {cardData?.isNew ? (
                        <Tag
                          css={{
                            c: "$on_surface_high",
                            r: "$0",
                            mt: "$6",
                            ml: "$4",
                            p: "$2 $4",
                          }}
                          label="NEW!"
                          variant="overline"
                        />
                      ) : null}
                    </Flex>
                    <Text
                      variant="xs"
                      css={{ color: "$on_surface_medium", mt: "$4" }}
                    >
                      {cardData.subText}
                    </Text>
                  </Flex>
                  <Text
                    variant="xs"
                    css={{
                      color: "$primary_bright",
                      mt: "$10",
                      cursor: "pointer",
                    }}
                    onClick={() => AppAnalytics.track(cardData.eventName)}
                  >
                    <a
                      target="_blank"
                      rel="no-referrer noreferrer"
                      href={cardData.link}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                      }}
                    >
                      {cardData.linkText}
                      <ArrowRightIcon height={14} width={14} />
                    </a>
                  </Text>
                </Flex>
              </Flex>
            )
          );
        })}
      </Flex>
    </Box>
  );
};

export default AppBoarding;
