import React, { useState } from "react";
import { isEmpty } from "lodash";
import CopyButton from "src/components/Common/CopyButton";
import SmallIcon from "src/components/Common/Icons/SmallIcon";
import TitleValue from "src/components/Common/TitleValue";
import { API_CALL_STATE } from "src/constants";
import { AppAnalytics } from "src/helpers";
import { roomRTMPStreamKeyType } from "src/types/roomCodeTypes";
import { roomDetailsType } from "src/types/roomTypes";
import {
  EyeCloseIcon,
  EyeOpenIcon,
  NoEntryIcon,
  RefreshIcon,
} from "@100mslive/react-icons";
import { Button, Flex, Loading, Text, Tooltip } from "@100mslive/roomkit-react";
import { getVisibleRoles } from "../../helpers/roles_helper";
import { formattedDate } from "../../utils";

/* eslint-disable complexity */
const RoomDetailsCard = ({
  room_id = "",
  room,
  role_names = [],
  template_id = "",
  isRoomActive = true,
  templateExists,
  setOpenWithActiveRole,
  isHLSEnabled,
  fetchRTMPStreamKeysStatus,
  disableRTMPStreamKeysStatus,
  createRoomRTMPKeys,
  roomRTMPDetails,
  createRoomRTMPKeysStatus,
  disableRTMPStreamKey,
  refreshRTMPStreamKey,
}: {
  roomRTMPDetails: Record<string, roomRTMPStreamKeyType>;
  createRoomRTMPKeysStatus: string;
  fetchRTMPStreamKeysStatus: string;
  room_id: string;
  room: roomDetailsType;
  role_names: string[];
  template_id: string;
  isRoomActive: boolean;
  templateExists: boolean;
  setOpenWithActiveRole: (str: string) => void;
  isHLSEnabled: boolean;
  createRoomRTMPKeys: (roomId: string) => void;
  disableRTMPStreamKeysStatus: string;
  disableRTMPStreamKey: (roomId: string) => void;
  refreshRTMPStreamKey: (roomId: string) => void;
}) => {
  const visibleRoles = getVisibleRoles(role_names);
  const [hideStreamKey, setHideStreamKey] = useState(true);
  return (
    <Flex
      css={{
        w: "100%",
        border: "$space$px solid $border_default",
        p: "$14",
        r: "$1",
        mt: "$12",
      }}
      direction="column"
    >
      <Flex css={{ gap: "$20", rowGap: "$13", flexWrap: "wrap" }}>
        {[
          {
            title: "Room Name",
            value: room?.name,
            showCopyButton: true,
            btnId: "room.name",
            componentId: "rooms.details.page",
          },
          {
            title: "Room ID",
            value: room_id,
            showCopyButton: true,
            btnId: "room.id",
            componentId: "rooms.details.page",
          },
          {
            title: "Template Name",
            value: room?.template || "",
            link: `/templates/${template_id}`,
          },
          {
            title: "Template ID",
            value: template_id,
            showCopyButton: true,
            link: `/templates/${template_id}`,
            btnId: "template.id",
            componentId: "rooms.details.page",
          },
          {
            title: "Region",
            value: room?.region ? room.region.toUpperCase() : "AUTO",
          },
          {
            title: "Created on",
            value: formattedDate(room?.created_at || ""),
          },
        ]
          .filter(row => row.value !== "")
          .map(row => (
            <TitleValue
              key={row.value}
              title={row.title}
              value={row.value}
              link={templateExists === false ? "" : row?.link}
              copyButton={!!row?.showCopyButton}
              btnId={row?.btnId || ""}
              componentId={row?.componentId || ""}
              textCss={
                row.link
                  ? {
                      "&:hover": { textDecoration: "underline" },
                      color: "$primary_bright",
                      fontWeight: 700,
                      fontFamily: "var(--user-font-roboto)",
                    }
                  : {}
              }
            />
          ))}
      </Flex>
      {isHLSEnabled ? (
        <Flex css={{ gap: "$20", flexWrap: "wrap" }}>
          <Flex direction="column" css={{ mt: "$14" }}>
            <Text
              variant="tiny"
              css={{
                color: "$on_surface_medium",
                fontWeight: "$semiBold",
                textTransform: "uppercase",
                mb: "$4",
              }}
            >
              RTMP STREAM KEY
            </Text>

            {fetchRTMPStreamKeysStatus === API_CALL_STATE.IN_PROGRESS ? (
              <Loading size={24} />
            ) : null}
            {fetchRTMPStreamKeysStatus !== API_CALL_STATE.IN_PROGRESS &&
            (isEmpty(roomRTMPDetails?.[room_id]?.key) || !isRoomActive) ? (
              <Tooltip
                title="The room should be enabled"
                disabled={isRoomActive}
              >
                <Button
                  variant="standard"
                  css={{ r: "$0", p: "$3 $4" }}
                  disabled={!isRoomActive}
                  onClick={() => {
                    createRoomRTMPKeys(room_id);
                  }}
                  loading={
                    createRoomRTMPKeysStatus === API_CALL_STATE.IN_PROGRESS
                  }
                >
                  Generate
                </Button>
              </Tooltip>
            ) : null}

            {fetchRTMPStreamKeysStatus !== API_CALL_STATE.IN_PROGRESS &&
            !isEmpty(roomRTMPDetails?.[room_id]?.key) &&
            isRoomActive ? (
              <Flex align="center" gap="2">
                <Text
                  variant="sub2"
                  css={{
                    c: "$on_surface_high",
                    textSecurity: hideStreamKey ? "disc" : "none",
                    "-webkit-text-security": hideStreamKey ? "disc" : "none",
                  }}
                >
                  {roomRTMPDetails?.[room_id]?.key}
                </Text>
                <Tooltip
                  title={`${hideStreamKey ? "Show" : "Hide"} Stream Key`}
                >
                  <Button
                    outlined
                    variant="standard"
                    onClick={async () => {
                      setHideStreamKey(!hideStreamKey);
                    }}
                    css={{
                      position: "relative",
                      display: "inline-block",
                      p: "$2",
                      borderRadius: "$0",
                      color: "$on_surface_medium",
                    }}
                  >
                    <SmallIcon>
                      {hideStreamKey ? <EyeOpenIcon /> : <EyeCloseIcon />}
                    </SmallIcon>
                  </Button>
                </Tooltip>
                <CopyButton value={roomRTMPDetails?.[room_id]?.key} />
                <Tooltip title="Reset Stream Key">
                  <Button
                    outlined
                    variant="standard"
                    onClick={async () => {
                      await refreshRTMPStreamKey(room_id);
                    }}
                    css={{
                      position: "relative",
                      display: "inline-block",
                      p: "$2",
                      borderRadius: "$0",
                      color: "$on_surface_medium",
                    }}
                    loading={
                      disableRTMPStreamKeysStatus ===
                        API_CALL_STATE.IN_PROGRESS ||
                      createRoomRTMPKeysStatus === API_CALL_STATE.IN_PROGRESS
                    }
                  >
                    <SmallIcon>
                      <RefreshIcon />
                    </SmallIcon>
                  </Button>
                </Tooltip>
                <Tooltip title="Disable Stream Key">
                  <Button
                    outlined
                    variant="standard"
                    loading={
                      disableRTMPStreamKeysStatus === API_CALL_STATE.IN_PROGRESS
                    }
                    onClick={async () => {
                      await disableRTMPStreamKey(room_id);
                    }}
                    css={{
                      position: "relative",
                      display: "inline-block",
                      p: "$2",
                      borderRadius: "$0",
                      color: "$on_surface_medium",
                    }}
                  >
                    <SmallIcon>
                      <NoEntryIcon />
                    </SmallIcon>
                  </Button>
                </Tooltip>
              </Flex>
            ) : null}
          </Flex>
          <Flex direction="column" css={{ mt: "$14" }}>
            <Text
              variant="tiny"
              css={{
                color: "$on_surface_medium",
                fontWeight: "$semiBold",
                textTransform: "uppercase",
                mb: "$4",
              }}
            >
              RTMP URL
            </Text>
            {fetchRTMPStreamKeysStatus === API_CALL_STATE.IN_PROGRESS ? (
              <Loading size={24} />
            ) : isEmpty(roomRTMPDetails?.[room_id]) || !isRoomActive ? (
              <Text variant="sub2" css={{ c: "$on_surface_high" }}>
                -
              </Text>
            ) : (
              <Flex align="center" gap="2">
                <Text variant="sub2" css={{ c: "$on_surface_high" }}>
                  {roomRTMPDetails?.[room_id]?.url}
                </Text>
                <CopyButton value={roomRTMPDetails?.[room_id]?.url} />
              </Flex>
            )}
          </Flex>
        </Flex>
      ) : null}
      <Flex direction="column" css={{ mt: "$14" }}>
        <Text
          variant="tiny"
          css={{
            color: "$on_surface_medium",
            fontWeight: "$semiBold",
            textTransform: "uppercase",
            mb: "$4",
          }}
        >
          Roles
        </Text>
        {isRoomActive ? (
          <Text
            variant="sm"
            css={{
              fontWeight: "700",
              display: "flex",
              flexWrap: "wrap",
              rowGap: "$2",
              color: visibleRoles.length
                ? "$primary_bright"
                : "$on_surface_high",
            }}
          >
            {visibleRoles.length
              ? visibleRoles.map((role: string, id: number) => (
                  <>
                    <Flex
                      key={role}
                      onClick={() => {
                        setOpenWithActiveRole(role);
                        AppAnalytics.track("btn.clicked", {
                          btnId: "roles.clicked",
                          componentId: "rooms.overview.page",
                          role: role,
                        });
                      }}
                      css={{ cursor: "pointer" }}
                    >
                      {role}
                    </Flex>
                    {id !== visibleRoles.length - 1 ? (
                      <Flex css={{ color: "$on_surface_high" }}>,&nbsp;</Flex>
                    ) : null}
                  </>
                ))
              : "-"}
          </Text>
        ) : (
          <Text
            variant="sm"
            css={{
              fontWeight: "700",
              color: "$on_surface_high",
            }}
          >
            {visibleRoles.length ? visibleRoles.join(", ") : "-"}
          </Text>
        )}
      </Flex>
    </Flex>
  );
};

export default RoomDetailsCard;
