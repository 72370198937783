import React from "react";
import moment from "moment";
import { ChevronRightIcon } from "@100mslive/react-icons";
import { Flex, Text } from "@100mslive/roomkit-react";

const EventCard = (
  { title, time, onClick, active, style = {}, error },
  ...props
) => {
  const date = new Date(time);
  return (
    <Flex
      css={{
        ...style,
        mb: "$sm",
        w: "98%",
        cursor: "pointer",
        bg: error ? " rgba(204, 82, 95, 0.2)" : "$surface_bright",
        "&:hover": {
          bg: error ? "rgba(179, 71, 82, 0.15)" : "$surface_dim",
        },
        p: "$md",
        paddingRight: "$xs",
        borderRadius: "$0",
        border: !active
          ? error
            ? "$space$px solid $alert_error_dim"
            : "$space$px solid $border_bright"
          : error
          ? "$space$px solid $alert_error_default"
          : "$space$px solid $primary_default",
      }}
      justify="between"
      align="center"
      onClick={onClick}
      {...props}
    >
      <Text
        variant="sm"
        css={{
          c: "$on_surface_high",
          fontWeight: "$semiBold",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
        }}
      >
        {title}
      </Text>
      <Flex justify="between" align="center">
        <Text
          variant="xs"
          css={{ c: "$on_surface_medium", w: "100%", whiteSpace: "nowrap" }}
        >
          {moment(date).format("MMM D YYYY, HH:mm:ss")}
        </Text>
        <Flex
          css={{ w: "$8", h: "$8", color: "$on_surface_medium" }}
          justify="center"
          align="center"
        >
          <ChevronRightIcon width={12} height={12} />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default EventCard;
