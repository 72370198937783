import React, { ReactNode, useEffect, useState } from "react";
import { ChevronDownIcon } from "@100mslive/react-icons";
import { Box, Flex, Text } from "@100mslive/roomkit-react";

export const PrebuiltSidebarAccordionSection = ({
  title = "",
  isActive = false,
  children,
  setActiveAccordion,
}: {
  title: string;
  isActive: boolean;
  children: ReactNode;
  setActiveAccordion: () => void;
}) => {
  const [openAccordion, setOpenAccordion] = useState(isActive);

  useEffect(() => {
    if (!isActive) {
      setOpenAccordion(false);
    }
  }, [isActive]);

  return (
    <Box css={{ mt: "$8" }}>
      <Flex
        justify="between"
        align="center"
        css={{
          w: "100%",
          color: "$on_surface_medium",
          cursor: "pointer",
          "&:hover": { color: "$on_surface_high" },
        }}
        onClick={() =>
          setOpenAccordion(prev => {
            if (!prev) {
              setActiveAccordion();
            }
            return !prev;
          })
        }
      >
        <Text
          variant="tiny"
          css={{
            textTransform: "uppercase",
            fontWeight: "$semiBold",
            letterSpacing: "1.5px",
            color: "inherit",
          }}
        >
          {title}
        </Text>
        <ChevronDownIcon
          style={{
            transform: openAccordion ? "rotate(180deg)" : "rotate(0deg)",
            transition: "transform 0.3s ease-in-out",
          }}
        />
      </Flex>
      <Box
        css={{
          maxHeight: openAccordion ? "100vh" : 0,
          overflowY: "hidden",
          transition: "max-height 0.3s ease-in-out",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
